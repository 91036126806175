import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { reject, pipe, join, not, pathOr } from 'ramda'

import {
  Divider,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  Typography,
  MdxWrapper,
  YouTube,
  Accordion,
  PressOfficeWidget,
} from '../../components'

import ContentRightSidebarLayout from '../../components/Layout/ContentRightSidebar/ContentRightSidebar'
import Img from '../../components/gatsbyWrappers/Img'
import styles from './ProjectPageTemplate.module.css'

const shortcodes = {
  Divider,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  YouTube,
}

/**
 * @todo (mdx):
 * Add fields to GraphQL schema
 * export partials that don't use MDX for reuse in CMS previews
 * @todo (mdx):  pass HOC as prop to return MDX renderer so we can reuse templates!
 */

export const ProjectPageTemplate = ({
  title,
  author,
  publishedDate,
  categories,
  featuredImage,
  editor,
  moreInfo,
}) => {
  const objectives = pathOr([], ['objectives'], moreInfo)
  const background = pathOr([], ['background'], moreInfo)
  const focus = pathOr([], ['focus'], moreInfo)
  const timeline = pathOr([], ['timeline'], moreInfo)

  const items = [
    objectives.length > 0 && {
      title: 'Project Objectives',
      iconUrl: '',
      /** @todo https://app.asana.com/0/1165868978601531/1175651635938779?lg=1589369130794 */
      content: objectives.map((o, i) => <div key={i}>{o}</div>),
    },
    background.length > 0 && {
      title: 'Project Background',
      iconUrl: '',
      content: background.map((o, i) => <div key={i}>{o}</div>),
    },
    focus.length > 0 && {
      title: 'Project Focus',
      iconUrl: '',
      content: focus.map((o, i) => <div key={i}>{o}</div>),
    },
    timeline.length > 0 && {
      title: 'Project Timeline',
      iconUrl: '',
      content: timeline.map((o, i) => <div key={i}>{o}</div>),
    },
  ].filter(a => !!a)

  return (
    <MDXProvider components={shortcodes}>
      {(categories || []).length > 0 && (
        <Typography tag="p" type="boxHeading" className="mb-8">
          {categories.join(', ')}
        </Typography>
      )}
      <Typography tag="h1" type="h2">
        {title}
      </Typography>
      <Typography tag="p" type="subHead2" className="mb-4">
        {pipe(
          reject(not),
          join(' | '),
        )([
          author && (
            <>
              <strong>Author:</strong> {author}
            </>
          ),
          publishedDate && (
            <>
              <strong>Published:</strong> {publishedDate}
            </>
          ),
        ])}
      </Typography>
      <MdxWrapper>
        <Img src={featuredImage} alt={title} className={styles.img} />
        <MDXRenderer>{editor}</MDXRenderer>
      </MdxWrapper>

      {items.length > 0 && (
        <>
          <Typography tag="h3" type="h2" className={styles.moreInfoTitle}>
            Find Out More
          </Typography>
          <Accordion items={items} className={styles.moreInfo} />
        </>
      )}
    </MDXProvider>
  )
}

ProjectPageTemplate.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  publishedDate: PropTypes.string,
  categories: PropTypes.array,
  featuredImage: PropTypes.string,
  editor: PropTypes.string,
  moreInfo: PropTypes.shape({
    objectives: PropTypes.arrayOf(PropTypes.string),
    background: PropTypes.arrayOf(PropTypes.string),
    focus: PropTypes.arrayOf(PropTypes.string),
    timeline: PropTypes.arrayOf(PropTypes.string),
  }),
}

const ProjectPage = ({ data }) => {
  const { frontmatter } = data.mdx
  const { header } = frontmatter

  return (
    <ContentRightSidebarLayout header={header}>
      <div>
        <ProjectPageTemplate {...frontmatter} />
      </div>
      <div>
        <PressOfficeWidget />
      </div>
    </ContentRightSidebarLayout>
  )
}

ProjectPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProjectPage

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      frontmatter {
        ...headerFields
        title
        author
        publishedDate
        categories
        featuredImage {
          childCloudinaryMediaImage {
            fixed(width: 400) {
              height
              src
              srcSet
              width
            }
          }
        }
        editor
        moreInfo {
          objectives {
            column
          }
          background {
            column
          }
          focus {
            column
          }
          timeline {
            column
          }
        }
      }
    }
  }
`
